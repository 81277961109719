.scroll-container {
    width: 100%;
    /* overflow: hidden; */
    white-space: nowrap;
}
  
.scroll-text {
    display: inline-block;

}
  
@keyframes marquee {
    to {
        transform: translateX(-100%);
    }
}
  